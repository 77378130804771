global.Coin = (coin, seperate, numFixed) => {
  coin = parseFloat(coin);
  coin = isNaN(coin) ? 0 : coin;
  seperate = seperate == null ? "," : ".";
  numFixed = numFixed == null ? 3 : numFixed;

  let str = "";
  if(coin.toString().indexOf(".") < 0)
  {
    str = coin.toFixed(1).toString().replace(/\d(?=(\d{3})+\.)/g, '$&' + seperate);
    str = str.replace(".0", "");
  }
  else
  {
    str = coin.toFixed(numFixed).replace(/\d(?=(\d{3})+\.)/g, '$&' + seperate);
  }
  return str;
};
