let crypto = require('crypto');

let Api = {};
// Api.URL = 'http://127.0.0.1:6005/';//local
// Api.URL = 'https://fish-admin-api.cano.love/';//dev
Api.URL = 'https://fish-admin-api.cano.love/'; //online
let key = 'DA12243DSSDFSR231';

Api.sendRequestBeforeLogin = (header, apiName, dataInput, callBackSuccess, callBackError) => {
  let stringData = '';
  let str_sign = "";
  let dataSign = {};

  for (let index in dataInput) {
    dataSign[index] = dataInput[index];
    str_sign += dataSign[index];
  }

  str_sign = str_sign + key;
  dataSign.Sign = crypto.createHash('md5').update(str_sign).digest('hex');
  for (let index in dataSign) {
    stringData += index + '=' + dataSign[index] + '&';
  }
  stringData = stringData.substring(0, stringData.length);

  sendData(header, apiName, callBackSuccess, callBackError, stringData);
};


Api.sendRequestAfterLogin = (header, apiName, dataInput, callBackSuccess, callBackError) => {
  let stringData = '';
  let str_sign = "";
  let dataSign = {};

  dataInput.Token = global.User.getUser().token;
  for (let index in dataInput) {
    dataSign[index] = dataInput[index];
    str_sign += dataSign[index];
  }

  str_sign = str_sign + key;
  dataSign.Sign = crypto.createHash('md5').update(str_sign).digest('hex');

  for (let index in dataSign) {
    stringData += index + '=' + dataSign[index] + '&';
  }
  stringData = stringData.substring(0, stringData.length);

  sendData(header, apiName, callBackSuccess, callBackError, stringData);
};

const sendData = (header, apiName, callBackSuccess, callBackError, stringData) => {
  let xmlHttpToken = new XMLHttpRequest();
  xmlHttpToken.open("POST", Api.URL + header + "/" + apiName + "", true);
  xmlHttpToken.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xmlHttpToken.onreadystatechange = () => {
    if (xmlHttpToken.readyState === 4) {

      if(xmlHttpToken.status === 200)
      {
        let data = JSON.parse(xmlHttpToken.responseText);

        if (data.Result) {
          if (typeof (callBackSuccess) === 'function' && callBackSuccess) {
            callBackSuccess(data);
          }
        }
        else {
          if (callBackError && typeof callBackError === 'function') {
            callBackError(data);
          }
        }
      }
      else if (callBackError && typeof callBackError === 'function') {
        callBackError({Message:"Không thể gọi api"});
      }
    }
  };

  xmlHttpToken.onerror = () => {
    console.log("API Error:", xmlHttpToken);
    if (callBackError && typeof callBackError === 'function') {
      callBackError({Message:"Lỗi mạng không xác định"});
    }
  };

  xmlHttpToken.send(stringData);
};

global.Api = Api;
