import React, { Component } from 'react';
import { Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT} from 'react-ladda';
import { Redirect } from 'react-router-dom';

import 'ladda/dist/ladda-themeless.min.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      title: "",
      content: ""
    };

    this.userName = "";
    this.password = "";
  }

  onSubmit(event)
  {
    event.preventDefault();
    this.setState({isLoading: true});

    var data = {};
    data.UserName = this.userName;
    data.Password = this.password;
    global.Api.sendRequestBeforeLogin('user','login', data, this.onLogin.bind(this), this.onLoginError.bind(this));
  }

  onLoginError(data)
  {
    this.setState({isLoading: false});
    if(!data.Result)
    {
      this.setState({title: "Thông báo", content: data.Message});
    }
  }

  onLogin(data)
  {
    if(!data.Result)
    {
      this.setState({isLoading: false, title: "Thông báo", content: data.Message})
    }
    else
    {
      sessionStorage.setItem('Token', data.Data.Token);
      sessionStorage.setItem('UserName', data.Data.UserName);

      global.User.login(data.Data);
      this.setState({isLoading: false});
    }
  }

  onClickPopup()
  {
    this.setState({title: "", content: "", isLoading: false});
  }

  handleChange(event)
  {
    this[event.target.id] = event.target.value;
  }

  render() {
    const isLogged = global.User.isLogged();

    if (isLogged) {
      return (
        <Redirect to="/Summary" />
      )
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="4">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.onSubmit.bind(this)}>
                      <h1>Đăng nhập</h1>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input id="userName" type="text" placeholder="UserName" autoComplete="username" onChange={this.handleChange.bind(this)}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input id="password" type="password" placeholder="Password" autoComplete="current-password" onChange={this.handleChange.bind(this)}/>
                      </InputGroup>
                      <Row className="justify-content-center">
                        <LaddaButton
                          className="btn btn-success btn-ladda"
                          loading={this.state.isLoading}
                          data-color="green"
                          data-style={EXPAND_LEFT}>Login</LaddaButton>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        {
          global.ShowPopup(this, this.state.title, this.state.content, this.onClickPopup)
        }
      </div>
    );
  }
}

export default Login;
