class User {
  constructor()
  {
    this.userID = 0;
    this.userName = "";
    this.token = "";
    this.adminType = 0;
    this.permission = null;
  }

  initData(data)
  {
    this.userName = data.UserName;
    this.token = data.Token;
    this.adminType = data.AdminType;
    this.permission = data.Permission;
  }
}

let user = null;
const getUser = () => {
  if(user === null)
  {
    user = new User();
  }
  return user;
};

const login = (data) => {
  let user = getUser();
  user.initData(data);
  return user;
};

const logout = (data) => {
  user = null;
};

const isLogged = (data) => {
  return user && user.token !== "";
};

global.User = {
  getUser: getUser,
  login: login,
  logout: logout,
  isLogged: isLogged
};
