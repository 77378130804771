export default {
  items: [
    {
      title: true,
      name: 'Hệ thống',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Tổng quan',
      url: '/Summary',
      icon: 'fa fa-dollar',
    },
    {
      name: 'Quản lý hệ thống',
      url: '/SystemControl',
      icon: 'icon-speedometer',
    },
    {
      name: 'Quản lý game',
      url: '/GameControl',
      icon: 'fa fa-gamepad',
    },
    {
      name: 'Quản lý user',
      url: '/UserManagement',
      icon: 'fa fa-user-o',
    },
    {
      name: 'User white-black',
      url: '/UserWhiteBlack',
      icon: 'fa fa-user-o',
    },
    /*{
      title: true,
      name: 'LOG',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: ''
    },
    {
      name: 'Log giết cá',
      url: '/LogKillFish',
      icon: 'icon-screen-desktop',
    },*/
    {
      title: true,
      name: 'Sự kiện',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: ''
    },
    {
      name: 'Quản lý GiftCode',
      url: '/giftcode',
      icon: 'fa fa-gift',
    },
    {
      name: 'Top bắn boss',
      url: '/TopShootBoss',
      icon: 'icon-puzzle',
    },
    {
      name: 'Top bắn cá',
      url: '/TopShootFish',
      icon: 'icon-puzzle',
    },
    {
      name: 'Vòng quay may mắn',
      url: '/LuckyWheel',
      icon: 'icon-puzzle',
    },
  ]
};
