import React from 'react';

// const SystemInformation = React.lazy(() => import('./views/SystemInformation'));
const UserManagement = React.lazy(() => import('./views/UserManagement'));
const UserWhiteBlack = React.lazy(() => import('./views/UserWhiteBlack'));
const SystemControl = React.lazy(() => import('./views/SystemControl'));
const GameControl = React.lazy(() => import('./views/GameControl'));
const GiftCode = React.lazy(() => import('./views/GiftCode'));
const Summary = React.lazy(() => import('./views/Summary'));
const EventTopShootBoss = React.lazy(() => import('./views/Events/EventTopShootBoss'));
const EventTopShootFish = React.lazy(() => import('./views/Events/EventTopShootFish'));
const EventLuckyWheel = React.lazy(() => import('./views/Events/EventLuckyWheel'));
//const KillFish = React.lazy(() => import('./views/Logs/KillFish'));

const routes = [
  //{path: '/', name: 'Home', component: DefaultLayout, exact: true},

  // {path: '/general', name: 'General', component: General},
  // {path: '/systeminformation', name: 'SystemInformation', component: SystemInformation},
  {path: '/UserManagement', name: 'Quản Lý User', component: UserManagement},
  {path: '/UserWhiteBlack', name: 'User white-black', component: UserWhiteBlack},
  {path: '/SystemControl', name: 'Quản Lý Hệ Thống', component: SystemControl},
  {path: '/GameControl', name: 'Quản Lý game', component: GameControl, exact: true},
  {path: '/GiftCode', name: 'Quản Lý GiftCode', component: GiftCode, exact: true},
  {path: '/Summary', name: 'Tổng Quan', component: Summary, exact: true},
  {path: '/TopShootBoss', name: 'Event Top Bắn Boss', component: EventTopShootBoss, exact: true},
  {path: '/TopShootFish', name: 'Event Top Bắn Cá', component: EventTopShootFish, exact: true},
  {path: '/LuckyWheel', name: 'Event Vòng quay may mắn', component: EventLuckyWheel, exact: true},
  //{path: '/LogKillFish', name: 'Log Giết Cá', component: KillFish, exact: true}
];

export default routes;
