import React, {Component} from 'react';
import {
  Modal,
  Button,
  ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state =
      {
        isOpen: true
      };

    this.title = props.title;
    this.content = props.content;
    this.onClickPopup = props.onClickPopup != null ? props.onClickPopup : this.onClickClose.bind(this);
  }

  onClickClose()
  {
    this.setState({isOpen: false});
  }

  render()
  {
    return (
      <Modal isOpen={this.state.isOpen} className={this.props.className}>
        <ModalHeader tag={"h6"} cssModule={{'modal-title': 'w-100 text-center'}}>{this.title}</ModalHeader>
        <ModalBody>
          {this.content}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onClickPopup}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

global.ShowPopup = (self, title, content, onClickPopup) => {
    if(title !== "" || content !== "")
    {
      return (
        <Popup className={self.className} title={title} content={content} onClickPopup={onClickPopup!=null ? onClickPopup.bind(self) : null}/>
      )
    }
    return (<div></div>);
};
